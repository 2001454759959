export const debounceWrapper = (fn, delay) => {
    let timeout;
    return (args) => {
        if (timeout)
            clearTimeout(timeout);
        timeout = setTimeout(() => fn(args), delay);
    };
};
export const throttleWrapper = () => {
    let lastCall = 0;
    let timeoutId;
    return (fn, delay) => (args) => {
        const now = Date.now();
        if (now - lastCall >= delay && !timeoutId) {
            fn(args);
            lastCall = now;
        }
        else {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                fn(args);
                lastCall = Date.now();
                clearTimeout(timeoutId);
            }, delay);
        }
    };
};
