var ContactProvider;
(function (ContactProvider) {
    ContactProvider["Facebook"] = "Facebook";
    ContactProvider["X"] = "X";
    ContactProvider["Instagram"] = "Instagram";
    ContactProvider["Discord"] = "Discord";
    ContactProvider["Twitch"] = "Twitch";
    ContactProvider["YouTube"] = "YouTube";
    ContactProvider["Reddit"] = "Reddit";
    ContactProvider["Telegram"] = "Telegram";
    ContactProvider["WhatsApp"] = "WhatsApp";
    ContactProvider["LinkedIn"] = "LinkedIn";
    ContactProvider["TikTok"] = "TikTok";
    ContactProvider["WebPage"] = "WebPage";
})(ContactProvider || (ContactProvider = {}));
export default ContactProvider;
