var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable max-len */
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/dist/query';
import restApiAuthorized from '@store/api/restApiAuthorized';
// import type { RootState } from '@app/store'
import { isEqualShallow } from '@utils/js-ts';
import { getTraderProfileActivity, mapContacts } from './utils';
const profileApi = restApiAuthorized.injectEndpoints({
    endpoints: (builder) => ({
        getProfileById: builder.query({
            query: ({ id }) => ({
                url: `/api/v1/profile/${id}`,
                method: 'GET',
            }),
            providesTags: (response, error, { id }) => [{ type: 'Profiles', id }],
            transformResponse: (response) => {
                const profile = response;
                // TODO: followers and following count will be a part of the model
                // that comes from backend
                // profile.followers = response.profileById.profileFollowingProfile.totalCount
                // profile.following = response.profileById.profileFollowingFollower.totalCount
                // profile.contacts = mapContacts(response.profileById)
                return profile;
            },
        }),
        getProfileByNickname: builder.query({
            query: ({ nickname }) => ({
                url: `/api/v1/profile/username/${nickname}`,
                method: 'GET',
            }),
            providesTags: (response, error, { nickname }) => [{ type: 'Profiles', nickname }],
            transformResponse: (response) => {
                const profile = response;
                // TODO: followers and following count will be a part of the model
                // that comes from backend
                // profile.followers = response.profileById.profileFollowingProfile.totalCount
                // profile.following = response.profileById.profileFollowingFollower.totalCount
                profile.contacts = mapContacts(response);
                return profile;
            },
        }),
        searchByNickname: builder.query({
            query: ({ nickname }) => ({
                url: `/api/v1/profile/username/${nickname}`,
                method: 'GET',
            }),
        }),
        getCountriesList: builder.query({
            query: () => ({
                url: '/api/v1/suggestions/countries',
                method: 'GET',
            }),
            providesTags: ['Countries'],
        }),
        getSocialNetworks: builder.query({
            query: () => ({
                url: '/api/v1/suggestions/social-networks',
                method: 'GET',
            }),
            providesTags: ['SocialNetworks'],
        }),
        updateProfile: builder.mutation({
            query: (_a) => {
                var { id } = _a, optionalParams = __rest(_a, ["id"]);
                return ({
                    url: `/api/v1/profile/${id}`,
                    method: 'PATCH',
                    body: Object.assign({}, optionalParams),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            },
            invalidatesTags: ['Me'],
        }),
        createProfile: builder.mutation({
            query: (profileFields) => ({
                url: '/api/v1/profile',
                method: 'POST',
                body: Object.assign({}, profileFields),
                variables: Object.assign({}, profileFields),
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            invalidatesTags: ['Me'],
        }),
        switchProfile: builder.mutation({
            query: ({ profileId }) => ({
                url: `/api/v1/profile/${profileId}/activate`,
                method: 'POST',
            }),
            invalidatesTags: ['Me'],
        }),
        followProfile: builder.mutation({
            query: ({ id, idToFollow }) => ({
                url: `/api/v1/profile/${id}/follow`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {
                    followedProfileId: idToFollow,
                },
            }),
            // async onQueryStarted({ id }, { getState, dispatch, queryFulfilled }) {
            //     try {
            //         await queryFulfilled
            //
            //         const myProfile = (getState() as RootState).profile.currentUserProfile
            //
            //         if (!myProfile) {
            //             return
            //         }
            //
            //         // updating Profile we've subscribed to
            //         dispatch(
            //             profileApi.util.updateQueryData('getProfileById', { id }, (draft) => {
            //                 draft.followers = (draft.followers || 0) + 1
            //                 draft.mutualSubscription = true
            //             }),
            //         )
            //
            //         // updating myProfile
            //         dispatch(
            //             profileApi
            //                 .util
            //                 .updateQueryData('getProfileById', { id: myProfile.id }, (draft) => {
            //                     draft.following = (draft.following || 0) + 1
            //                 }),
            //         )
            //
            //         // updating ProfileFollowers/Followings,
            //         // changing follow/unfollow state if Followed Profile exists,
            //         // otherwise invalidating that cache
            //         profileApi
            //             .util
            //             .selectInvalidatedBy(getState() as RootState, ['ProfileFollowers', 'ProfileFollowings'])
            //             .forEach(({ endpointName, originalArgs }) => {
            //                 if (endpointName === 'getProfileFollowers') {
            //                     dispatch(
            //                         profileApi.util.updateQueryData(endpointName, originalArgs, (followers) => {
            //                             const profileIndex = followers
            //                                 .findIndex(p => p.id === String(id))
            //
            //                             if (profileIndex !== -1) {
            //                                 followers[profileIndex].mutualSubscription = true
            //                             } else {
            //                                 dispatch(profileApi.util.invalidateTags([{ type: 'ProfileFollowers', id: originalArgs.id }]))
            //                             }
            //                         }),
            //                     )
            //                 }
            //
            //                 if (endpointName === 'getProfileFollowings') {
            //                     dispatch(
            //                         profileApi.util.updateQueryData(endpointName, originalArgs, (followings) => {
            //                             const profileIndex = followings
            //                                 .findIndex(p => p.id === String(id))
            //
            //                             if (profileIndex !== -1) {
            //                                 followings[profileIndex].mutualSubscription = true
            //                             } else {
            //                                 dispatch(profileApi.util.invalidateTags([{ type: 'ProfileFollowings', id: originalArgs.id }]))
            //                             }
            //                         }),
            //                     )
            //                 }
            //             })
            //     } catch {
            //     // TODO: figure out how we want to handle such errors
            //         console.warn('unhandled error')
            //     }
            // },
        }),
        unfollowProfile: builder.mutation({
            query: ({ id, idToUnfollow }) => ({
                url: `/api/v1/profile/${id}/unfollow`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {
                    followedProfileId: idToUnfollow,
                },
            }),
            // async onQueryStarted({ id }, { getState, dispatch, queryFulfilled }) {
            //     try {
            //     // awaiting that the mutation has been completed successfuly
            //         await queryFulfilled
            //
            //         const myProfile = (getState() as RootState).profile.currentUserProfile
            //
            //         if (!myProfile) {
            //             return
            //         }
            //
            //         // updating Profile we've subscribed to
            //         dispatch(
            //             profileApi
            //                 .util
            //                 .updateQueryData('getProfileById', { id }, (draft) => {
            //                     const followersCount = (draft.followers || 0) - 1
            //
            //                     draft.followers = followersCount > 0 ? followersCount : 0
            //                     draft.mutualSubscription = false
            //                 }),
            //         )
            //
            //         // updating myProfile
            //         dispatch(
            //             profileApi
            //                 .util
            //                 .updateQueryData('getProfileById', { id: myProfile.id }, (draft) => {
            //                     const followingsCount = (draft.following || 0) - 1
            //
            //                     draft.following = followingsCount > 0 ? followingsCount : 0
            //                 }),
            //         )
            //
            //         // updating ProfileFollowers/Followings,
            //         // changing follow/unfollow state if Followed Profile exists,
            //         // otherwise invalidating that cache
            //         profileApi
            //             .util
            //             .selectInvalidatedBy(getState() as RootState, ['ProfileFollowers', 'ProfileFollowings'])
            //             .forEach(({ endpointName, originalArgs }) => {
            //                 if (endpointName === 'getProfileFollowers') {
            //                     dispatch(
            //                         profileApi.util.updateQueryData(endpointName, originalArgs, (followers) => {
            //                             const profileIndex = followers
            //                                 .findIndex(p => p.id === String(id))
            //
            //                             if (profileIndex !== -1) {
            //                                 followers[profileIndex].mutualSubscription = false
            //                             } else {
            //                                 dispatch(profileApi.util.invalidateTags([{ type: 'ProfileFollowers', id: originalArgs.id }]))
            //                             }
            //                         }),
            //                     )
            //                 }
            //
            //                 if (endpointName === 'getProfileFollowings') {
            //                     dispatch(
            //                         profileApi.util.updateQueryData(endpointName, originalArgs, (followings) => {
            //                             const profileIndex = followings
            //                                 .findIndex(p => p.id === String(id))
            //
            //                             if (profileIndex !== -1) {
            //                                 followings[profileIndex].mutualSubscription = false
            //                             } else {
            //                                 dispatch(profileApi.util.invalidateTags([{ type: 'ProfileFollowings', id: originalArgs.id }]))
            //                             }
            //                         }),
            //                     )
            //                 }
            //             })
            //     } catch {
            //     // TODO: figure out how we want to handle such errors
            //         console.warn('unhandled error')
            //     }
            // },
        }),
        getProfileFollowers: builder.query({
            query: ({ id, page, pageSize }) => ({
                url: `/api/v1/profile/${id}/followers`,
                method: 'GET',
                params: { page, page_size: pageSize },
            }),
            providesTags: (result, error, args) => [{ type: 'ProfileFollowers', id: args.id }],
            transformResponse: (response) => response.items,
            merge: (currentCache, newItems, { arg }) => {
                if (arg.page && arg.page > 1) {
                    currentCache.push(...newItems);
                    return currentCache;
                }
                return newItems;
            },
            // NOTE: in order to use "merge" logic we ommiting "take" and "skip" args
            // from the cache entry name
            serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition, }) => defaultSerializeQueryArgs({
                endpointName,
                queryArgs: { id: queryArgs.id },
                endpointDefinition,
            }),
            forceRefetch({ currentArg, previousArg }) {
                return !isEqualShallow(currentArg, previousArg);
            },
        }),
        getProfileFollowings: builder.query({
            query: ({ id, page, pageSize }) => ({
                url: `/api/v1/profile/${id}/followings`,
                method: 'GET',
                params: { page, page_size: pageSize },
            }),
            providesTags: (result, error, args) => [{ type: 'ProfileFollowings', id: args.id }],
            transformResponse: (response) => response.items,
            merge: (currentCache, newItems, { arg }) => {
                if (arg.page && arg.page > 0) {
                    currentCache.push(...newItems);
                    return currentCache;
                }
                return newItems;
            },
            // NOTE: in order to use "merge" logic we ommiting "take" and "skip" args
            // from the cache entry name
            serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition, }) => defaultSerializeQueryArgs({
                endpointName,
                queryArgs: { id: queryArgs.id },
                endpointDefinition,
            }),
            forceRefetch({ currentArg, previousArg }) {
                return !isEqualShallow(currentArg, previousArg);
            },
        }),
        searchItemsByName: builder.query({
            query: () => ({
                url: '',
                method: 'GET',
            }),
        }),
        getTraderActivity: builder.query({
            query: () => ({
                url: '/api/v1/auth/me',
                method: 'GET',
            }),
            transformResponse: () => getTraderProfileActivity()
        })
    }),
});
export default profileApi;
export const { useUpdateProfileMutation, useGetProfileByNicknameQuery, useLazyGetProfileByNicknameQuery, useLazyGetProfileByIdQuery, useGetProfileByIdQuery, useCreateProfileMutation, useGetCountriesListQuery, useLazyGetCountriesListQuery, useGetSocialNetworksQuery, useLazyGetSocialNetworksQuery, useSwitchProfileMutation, useGetProfileFollowersQuery, useGetProfileFollowingsQuery, useFollowProfileMutation, useUnfollowProfileMutation, useLazySearchItemsByNameQuery, useSearchItemsByNameQuery, useSearchByNicknameQuery, useLazySearchByNicknameQuery, useGetTraderActivityQuery, } = profileApi;
