export var PlatformSubscriptionTier;
(function (PlatformSubscriptionTier) {
    PlatformSubscriptionTier["Starter"] = "STARTER";
    PlatformSubscriptionTier["Advanced"] = "ADVANCED";
    PlatformSubscriptionTier["Professional"] = "PROFESSIONAL";
})(PlatformSubscriptionTier || (PlatformSubscriptionTier = {}));
export var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["Checkouting"] = "CHECKOUTING";
    SubscriptionStatus["Trialing"] = "TRIALING";
    SubscriptionStatus["Active"] = "ACTIVE";
    SubscriptionStatus["Canceled"] = "CANCELED";
    SubscriptionStatus["Incomplete"] = "INCOMPLETE";
    SubscriptionStatus["IncompleteExpired"] = "INCOMPLETE_EXPIRED";
    SubscriptionStatus["PastDue"] = "PAST_DUE";
    SubscriptionStatus["Paused"] = "PAUSED";
    SubscriptionStatus["Unpaid"] = "UNPAID";
})(SubscriptionStatus || (SubscriptionStatus = {}));
export var SubscriptionType;
(function (SubscriptionType) {
    SubscriptionType["Trader"] = "TRADER";
    SubscriptionType["Portal"] = "PORTAL";
})(SubscriptionType || (SubscriptionType = {}));
