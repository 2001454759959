const TRADER_ACTIVITY_MOCK = [{
        date: 'Fri Apr 12 2024',
        visitors: 0,
        copiers: 0,
        income: 0,
        payout: 0,
        refunds: 0,
        available_payout: 0,
    },
    {
        date: 'Sat Apr 13 2024',
        visitors: 0,
        copiers: 0,
        income: 0,
        payout: 0,
        refunds: 0,
        available_payout: 0,
    },
    {
        date: 'Sun Apr 14 2024',
        visitors: 0,
        copiers: 0,
        income: 0,
        payout: 0,
        refunds: 0,
        available_payout: 0,
    },
    {
        date: 'Mon Apr 15 2024',
        visitors: 0,
        copiers: 0,
        income: 0,
        payout: 0,
        refunds: 0,
        available_payout: 0,
    },
    {
        date: 'Tue Apr 16 2024',
        visitors: 16,
        copiers: 1,
        income: 25,
        payout: 0,
        refunds: 0,
        available_payout: 0,
    },
    {
        date: 'Wed Apr 17 2024',
        visitors: 15,
        copiers: 1,
        income: 0,
        payout: 0,
        refunds: 0,
        available_payout: 0,
    },
    {
        date: 'Thu Apr 18 2024',
        visitors: 15,
        copiers: 1,
        income: 0,
        payout: 0,
        refunds: 0,
        available_payout: 0,
    },
    {
        date: 'Fri Apr 19 2024',
        visitors: 10,
        copiers: 1,
        income: 0,
        payout: 0,
        refunds: 0,
        available_payout: 0,
    },
    {
        date: 'Sat Apr 20 2024',
        visitors: 17,
        copiers: 1,
        income: 0,
        payout: 0,
        refunds: 0,
        available_payout: 0,
    },
    {
        date: 'Sun Apr 21 2024',
        visitors: 22,
        copiers: 1,
        income: 1,
        payout: 0,
        refunds: 0,
        available_payout: 5,
    },
    {
        date: 'Mon Apr 22 2024',
        visitors: 30,
        copiers: 1,
        income: 0,
        payout: 0,
        refunds: 0,
        available_payout: 0,
    },
    {
        date: 'Tue Apr 23 2024',
        visitors: 0,
        copiers: 1,
        income: 0,
        payout: 0,
        refunds: 0,
        available_payout: 0,
    },
];
export { 
// eslint-disable-next-line import/prefer-default-export
TRADER_ACTIVITY_MOCK, };
